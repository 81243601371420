const LOGINHISTORY_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    height: 510,
    striped: true,
    pagination: false,
    pageSize: 10,
    pageList: [5, 10, 25, 50, 100, 200],
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: false,
    singleSelect: true,
    showToggle: false,
    maintainSelected: true,
    sortName: 'created_at',
    sortOrder: 'desc',
    uniqueId: 'guid',
}

const LOGINHISTORY_TABLE_COLUMS = [
  {
    field: 'user_name',
    title: '사용자 이름',
    align: 'center',
    valign: 'top',
    sortable: false,
  },
  {
    field: 'user_id',
    title: '사용자 ID',
    align: 'center',
    valign: 'top',
    sortable: false,
  }, 
  {
    field: 'service_type',
    title: '서비스타입',
    align: 'center',
    valign: 'top',
    sortable: false,
  },
  {
    field: 'created_at',
    title: '로그인 시간',
    align: 'center',
    valign: 'top',
    sortable: true,
  }
]
export {
    LOGINHISTORY_TABLE_OPTIOINS,
    LOGINHISTORY_TABLE_COLUMS
}
