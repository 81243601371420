<template>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol col="12" xl="12">
            <v-table id="loginHistoryTable" :columns="columns" :data="data" :options="options"></v-table>
          </CCol>
        </CRow>
        <CButton color="success" class="float-right" @click="saveHistory()">엑셀저장</CButton>
        <Progress
            ref="progressDialog"
            title="확인"
        />
        <Notify ref="notifyDialog"/>           
      </CCardBody>
  </CCard>
  

</template>

<script>

import loopback from '@/services/loopback';

import { LOGINHISTORY_TABLE_OPTIOINS, LOGINHISTORY_TABLE_COLUMS }
  from "@/constants/loginHistroySettings"
import utils from '@/services/utils'
import Progress from '@/views/popups/Progress';
import XLSX from 'xlsx';
import moment from 'moment';

export default {
  name: 'LoginHistory',
  components: {
    Progress
  },  
  created() {
    _.find(this.columns, { field: 'created_at' }).formatter = utils.dateFormatter;
    this.query();
  },
  data () {
    var table_options = _.cloneDeep(LOGINHISTORY_TABLE_OPTIOINS);
    table_options.search = false;
    return {
      options: table_options,
      columns: _.cloneDeep(LOGINHISTORY_TABLE_COLUMS),
      data: [],
    }
  },
  methods: {
    query() {
      var self = this;
      return loopback.get('/loginlogs')
        .then(res => {
          self.data = [];
          res.forEach(function(row, index0) {
            self.data.push({
              created_at: row.created_at,
              user_id: row.user_id,
              user_name: row.user_name,
              service_type: row.service_type,
            })
          })
          return Promise.resolve(self.data)
        })
    },

    makeFileName(name) {
      var to = moment(new Date()).format('YYYY-MM-DD');
      return name + "_" + to;
    },
    saveHistory() {
      var self = this;
      console.log(this.data)
      if (_.isEmpty(this.data)) {
        this.$refs.notifyDialog.show('검색된 데이터가 없습니다.');
        return;
      }

      var write = function(callback) {
        var historyData = self.data;
        var r = [];
        var wscols = [
          { wch: 20 },
          { wch: 15 },
          { wch: 20 },
          { wch: 25 }
        ];

        historyData.forEach(function(data, i) {
          var row = {
            '사용자 이름': data.user_name,
            '사용자 ID': data.user_id,
            '로그인 시간': moment(data.created_at).format('YYYY-MM-DD HH:mm:ss'),
          }
          r.push(row);
        })

        var ws = XLSX.utils.json_to_sheet(r);
        ws['!cols'] = wscols;

        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '로그인 이력');
        var filename = self.makeFileName('로그인이력');
        XLSX.writeFile(wb, filename + ".xlsx");
      }
      
      this.$refs.progressDialog.show({message: '요청 데이터를 저장중입니다.', counter:50, callback: write})
    }
  }
}
</script>